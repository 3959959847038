<template>
  <BaseLayout v-slot>
    <Breadcrumbs />
    <section class="login">
      <b-container>
        <b-row>
          <b-col
            offset-lg="1"
            offset-xl="2"
            lg="10"
            xl="8"
            class="position-relative"
          >
            <span class="login-title bg-dark text-white">{{
              $t("login")
            }}</span>
            <div class="login-component">
              <div
                class="d-flex flex-column flex-md-row justify-content-between"
              >
                <div class="info login--inner-component pb-25 pb-md-0">
                  <span class="title d-block">{{ $t("new_customers") }}</span>
                  <p class="mb-15 mb-md-45">
                    {{ $t("register_info") }}
                  </p>
                  <div class="text-right py-4">
                    <b-button
                      @click="$router.push($link('/create-account'))"
                      variant="info"
                      class="text-white py-3 px-15"
                      >{{ $t("create_account") }}</b-button
                    >
                  </div>
                </div>
                <div class="form login--inner-component pt-25 pt-md-0">
                  <span class="title d-block">{{
                    $t("registered_customers")
                  }}</span>
                  <span class="form-description d-block mb-30">{{
                    $t("account_login")
                  }}</span>

                  <b-form-group
                    id="email-group"
                    :label="$t('req_email')"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="loginData.email"
                      type="email"
                      required=""
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="password-group"
                    :label="$t('req_password')"
                    label-for="password"
                  >
                    <b-form-input
                      id="password"
                      v-model="loginData.password"
                      type="password"
                      required=""
                    ></b-form-input>
                  </b-form-group>
                  <span class="form-info mb-15 d-block">{{
                    $t("required_fields")
                  }}</span>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <b-link
                      :to="$link('/forgot-password')"
                      class="font-weight-normal link-underline"
                      >{{ $t("password_forgot") }}</b-link
                    >
                    <b-button
                      type="button"
                      variant="info"
                      @click="login"
                      class="text-white ml-20 form-submit-btn"
                      >{{ $t("login") }}</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import Breadcrumbs from "@/esf_lommel_dt1_europe/core/components//Breadcrumbs";
import Login from "@storefront/core/modules/user/mixins/login";
//import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "Login",
  mixins: [Login],
  components: {
    BaseLayout,
    Breadcrumbs,
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("login") },
        { name: "keywords", content: this.$t("login") },
        { name: "description", content: this.$t("login") },
      ],
      title: this.$t("login"),
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  watch: {
    isLoggedIn() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        this.$router.push({ name: "account" });
      }
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("login"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    formSubmit() {
      console.log("form Submitted");
    },
  },
};
</script>

<style lang="scss">
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";

section.login {
  padding-top: 37px;
  padding-bottom: 21px;

  @include media("screen", ">=tablet") {
    padding-top: 34px;
    padding-bottom: 56px;
  }

  .login-title {
    font-size: 20px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 9px 10px;
    min-width: 133px;
    text-align: center;
    font-weight: normal;
    z-index: 8;
  }

  .login-component {
    border: 2px solid #d7d7d7;
    padding: 42px 15px 19px 15px;
    position: relative;

    @include media("screen", ">=tablet") {
      padding: 50px 16px 37px 19px;
    }

    &:after {
      content: none;
      position: absolute;
      top: 56px;
      bottom: 30px;
      left: 50%;
      width: 1px;
      transform: translateX(-50%);
      background: #d7d7d7;

      @include media("screen", ">=tablet") {
        content: "";
      }
    }
  }

  .title {
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .login--inner-component {
    width: 100%;

    @include media("screen", ">=tablet") {
      width: calc(50% - 19px);
    }

    &.info {
      border-bottom: 1px solid #d7d7d7;

      @include media("screen", ">=tablet") {
        border: none;
      }
    }
  }

  button {
    font-weight: normal;
    font-size: 17px;
  }

  label {
    margin-bottom: 9px;
  }

  input[type="email"] {
    font-family: "Gibson Regular", sans-serif;
  }

  input[type="email"],
  input[type="password"] {
    border-radius: 4px;
  }

  .form-info {
    margin-top: -3px;
  }

  .form-submit-btn {
    padding: 3px 18px;
  }

  .link-underline {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
